<template>
  <intersect
    @enter="productSliderIntersect = 'Intersected'"
    @leave="productSliderIntersect = 'Not intersected'"
    v-if="categoriesNotEmpty.length && productsAll.length"
  >
    <div class="catalog-slider">
      <!-- <div class="container" style="height: 450px"> -->
      <div class="container catalog-banner">
        <catalog-banner
          :products="productsAll[productsIndex]"
          :currentIndex="productsIndex"
        />
      </div>
      <div
        v-if="categoriesNotEmpty.length && productsAll.length"
        class="slider-pagination"
      >
        <div class="container">
          <div class="slider-pagination__inner">
            <h3 class="slider-pagination__title">
              {{ nextActionStart }}
              <!-- Самые выгодные скидки до {{ getNextDay }} июля -->
            </h3>
            <swiper
              ref="swiperSliderBottom"
              class="slick-pagination"
              :options="swiperOptions"
              :class="{ initialization: isInitialization }"
              @ready="sliderReady"
            >
              <swiper-slide
                v-for="(item, index) in categoriesNotEmpty"
                :key="index"
                class="slider-pagination__item"
                :class="{
                  'real-active-slide': index === productsIndex,
                }"
              >
                <div
                  :class="`slider-pagination__item-box slider-pagination__item-box-${index}`"
                  :style="{ backgroundImage: `url(${getImage(item)})` }"
                >
                  <h6 class="slider-pagination__item-title">
                    {{ item.attributes.label }}
                  </h6>
                </div>

                <div class="wrapper swiper-pagination-bullet-active">
                  <div class="pie spinner"></div>
                  <div class="pie filler"></div>
                  <div class="mask"></div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </intersect>

  <div v-else>
    <div class="slider-placeholder"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getProductsByFilters } from "@/api/products";
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/catalog";
import Intersect from "vue-intersect";
import { eventBus } from "@/helpers";

export default {
  name: "CatalogSlider",
  components: {
    CatalogBanner: () =>
      import("@/components/sliders/catalog/CatalogBanner.vue"),
    Intersect,
  },
  data() {
    return {
      autoplayTime: 11000,
      // autoplayTime: 10000000600,
      swiperOptions: {
        slidesPerView: "auto",
        freeMode: true,
        centeredSlides: false,
        initialSlide: 0,
        mousewheel: {
          releaseOnEdges: true,
        },
        loop: true,
        on: {
          // scroll: () => {
          //   this.isInitialization = false;
          //   this.isScrolled = true;
          // },
          click: (e) => {
            this.isInitialization = true;
            const clickedSlideRealIndex = parseInt(
              e.clickedSlide.dataset.swiperSlideIndex
            );

            if (clickedSlideRealIndex !== this.productsIndex) {
              clearInterval(this.autoplayInterval);
              this.autoplayInterval = setInterval(
                this.customAutoplay,
                this.autoplayTime
              );
              this.productsIndex = clickedSlideRealIndex;
              this.$refs.swiperSliderBottom.swiperInstance.slideTo(
                clickedSlideRealIndex,
                100,
                false
              );
            }
          },
        },
      },
      isInitialization: false, // vue nuxt
      productsIndex: 0,
      viewedSlides: [],
      productSliderIntersect: "",
      productsAll: [],
      slides: null,
      isScrolled: false, // if user started scroll swiper slideTo turn off;
      swiper: null,
      autoplayInterval: null,
      backgroundImages: [
        { path: "fruits.png", id: 34 },
        { path: "gastronomy.png", id: 9 },
        { path: "soft-drinks.png", id: 13 },
        { path: "confectionery.png", id: 10 },
        { path: "grocery.png", id: 1 },

        { path: "dairy.png", id: 12 },
        { path: "own-production.png", id: 5 },
        { path: "tea.png", id: 7 },
        { path: "canned-food.png", id: 11 },
        { path: "frozen-food.png", id: 18 },

        { path: "household-chemicals.png", id: 6 },
        { path: "childrens-products.png", id: 8 },
        { path: "textile.png", id: 15 },
        { path: "other-products.png", id: 16 },
        { path: "meat.png", id: 35 },

        { path: "meat.png", id: 40 }, //Канцелярские

        { path: "hygiene-products.png", id: 6 },
      ],
      localMonth: {
        января: "қаңтарға",
        февраля: "ақпанға",
        марта: "наурызға",
        апреля: "сәуірге",
        мая: "мамырға",
        июня: "маусымға",
        июля: "шілдеге",
        августа: "тамызға",
        сентября: "қыркүйекке",
        октября: "қазанға",
        ноября: "қарашаға",
        декабря: "желтоқсан",
      },
    };
  },

  computed: {
    ...mapState("catalogModule", ["categories"]),
    ...mapState("cityModule", { selectedCity: "selectedCity" }),
    realIndex() {
      return this.swiper?.realIndex || 0;
    },
    nextActionStart() {
      const date = this.nextWeekDay(4);
      let dateRU;
      if (this.$isMobile()) {
        dateRU = new Intl.DateTimeFormat("ru-Ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }).format(date);
        const dayOfMonth = date.getDate();
        const splitedDateRu = dateRU.split(".");
        return `${this.$t("discountsUntil")} ${dayOfMonth}.${splitedDateRu[1]}`;
      } else {
        dateRU = new Intl.DateTimeFormat("ru-Ru", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(date);
        const dayOfMonth = date.getDate();
        const splitedDateRu = dateRU.split(" ");
        return this.$i18n.locale === "ru"
          ? `${this.$t("discountsUntil")} ${dayOfMonth} ${splitedDateRu[1]}`
          : `${this.$t("discountsUntil")}  ${dayOfMonth} ${
              this.localMonth[splitedDateRu[1]]
            } ${this.$t("since")}`;
      }
    },
    categoriesNotEmpty: {
      get() {
        return this.categories;
      },
      set(newVal) {
        this.setCategories(newVal);
      },
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.productsAll.length) {
        this.viewedSlides.push(this.productsIndex);
        this.viewProducts(this.productsAll[this.productsIndex]);
      }
    }, 1000);
    this.getProductsByCategories();
  },
  watch: {
    selectedCity: {
      handler() {
        this.getProductsByCategories();
      },
    },
    productsIndex: {
      handler() {
        if (
          this.productSliderIntersect === "Intersected" &&
          !this.viewedSlides.includes(this.productsIndex)
        ) {
          this.viewedSlides.push(this.productsIndex);
          this.viewProducts(this.productsAll[this.productsIndex]);
        }
      },
    },
    productSliderIntersect: {
      handler() {
        if (
          this.productSliderIntersect === "Intersected" &&
          !this.viewedSlides.includes(this.productsIndex)
        ) {
          this.viewedSlides.push(this.productsIndex);
          this.viewProducts(this.productsAll[this.productsIndex]);
        }
      },
    },
    categories() {
      this.getProductsByCategories();
    },
  },
  beforeDestroy() {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);
    }
  },
  methods: {
    ...mapActions("catalogModule", {
      getPdfProducts: actionTypes.getPdfProducts,
    }),
    viewProducts(products) {
      eventBus.$emit("gtm", {
        event: "view_item_list",
        type: "view_item_list",
        listName: "Каталог скидок",
        items: products,
        category: "",
      });
    },
    getImageIcon(id) {
      const image = this.backgroundImages.find((icon) => {
        return icon.id === id;
      });
      if (image) {
        return {
          backgroundImage: `url(${require(`@/assets/img/catalog/${image.path}`)})`,
        }; //`url(${require(@/assets/img/catalog/${image.path})
      }
      return { backgroundImage: "url('https://via.placeholder.com/350x150')" };
    },
    getImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.image?.data?.attributes?.url
      );
    },
    nextWeekDay(x) {
      var now = new Date();
      now.setDate(now.getDate() + ((x + (7 - now.getDay())) % 7));
      return now;
    },
    async getProductsByCategories() {
      for (const category of this.categories) {
        getProductsByFilters(
          {
            categories: [category.id],
            discountTypes: [],
            shopTypes: [],
          },
          null,
          { page: 1, pageSize: 10 }
        ).then((res) => {
          if (
            res.data.length === 0 &&
            this.categories.find((e) => e.id === category.id)
          ) {
            console.log(
              category.id,
              " ",
              this.categories.find((e) => e.id === category.id),
              this.categories.findIndex((e) => e.id === category.id)
            );
            this.categories.splice(
              this.categories.findIndex((e) => e.id === category.id),
              1
            );
          }
        });
      }
      const productsPromise = [];

      for (const category of this.categories) {
        const result = getProductsByFilters(
          {
            categories: [category.id],
            discountTypes: [],
            shopTypes: [],
          },
          null,
          { page: 1, pageSize: 10 }
        );
        productsPromise.push(result);
      }

      const resulsts = await Promise.all(productsPromise);

      if (this.$isMobile()) {
        this.productsAll = resulsts
          .filter((result) => {
            return result.data?.length > 0;
          })
          .map((result) => result.data.slice(0, 5));
      } else {
        this.productsAll = resulsts
          .filter((result) => {
            return result.data?.length > 0;
          })
          .map((result) => result.data.splice(0, 10));
      }

      let pdfProducts = [];
      this.productsAll
        .map((e) => e.slice(0, 2))
        .forEach((e) => {
          pdfProducts.push(...e);
        });
      let productBlocks = [];
      const chunkSize = 9;
      for (let i = 0; i < pdfProducts.length; i += chunkSize) {
        const chunk = pdfProducts.slice(i, i + chunkSize);
        productBlocks.push({ data: chunk });
      }
      this.getPdfProducts(productBlocks);
    },

    sliderReady(swiper) {
      this.swiper = swiper;

      this.isInitialization = true;

      // Off swiper pagination when start touch on mobile
      swiper.on("touchStart", (e) => {
        console.log(e);
        if (this.$isMobile()) {
          this.isInitialization = true;
          this.isScrolled = true;
        }
      });

      this.swiper.slideTo(0, null, null);
      clearInterval(this.autoplayInterval);

      //
      // eslint-disable-next-line no-constant-condition
      setTimeout(() => {
        this.autoplayInterval = setInterval(
          this.customAutoplay,
          this.autoplayTime
        );
      }, 1000);
    },
    customAutoplay() {
      this.productsIndex += 1;

      if (this.productsIndex >= this.categoriesNotEmpty?.length) {
        this.productsIndex = 0;
      }

      if (!this.isScrolled) {
        this.swiper.slideToLoop(this.productsIndex, 100, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-pagination__inner {
  display: flex;
  align-items: center;
}
.slider-placeholder {
  height: 550px;
  margin-bottom: 30px;
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  -webkit-animation: shine-data-v-7c5400c7 1s ease infinite;
  animation: shine-data-v-7c5400c7 2s linear infinite;
}

@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

.catalog-slider {
  // min-height: 550px;
  transition: all 0.3s linear;
}

.slider-pagination__item-box {
  background-color: #fff;
}

.slider-pagination {
  background-color: #fce300;
  padding: 25px 0;
  margin-bottom: 30px;

  &.no-animate {
    .initialization .real-active-slide {
      background-color: #f50f64;
      .wrapper {
        display: none;
      }
      .slider-pagination__item-box {
        border: 5px solid #f50f64;
        width: 95%;
        height: 95%;
        background-color: #fff;
      }
    }
  }

  &__title {
    font-family: "CeraPro Black";
    font-size: 28px;
    line-height: 110%;
    color: #f50f64;
    max-width: 350px;
    margin-right: 22px;
    padding: 15px 10px 15px;
    border-right: 2px solid #ffffff;
    float: left;
  }
}

.slider-pagination__item {
  cursor: pointer;
  border-radius: 18.2288px;
  max-width: 80.75px !important;
  height: 80.8px;
  // margin-left: 25px;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  outline: none;
  &-box {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 90%;
    height: 90%;
    padding: 7px 5px;
    z-index: 10;
    border-radius: 15px;
    transform: translateZ(0);
  }

  &-title {
    font-weight: normal;
    font-size: 9px;
    line-height: 9px;
    color: #2d2f32;
    white-space: normal;
    max-width: 100%;
    word-wrap: break-word;
  }

  .wrapper {
    display: none;
    position: absolute;
    top: -8px;
    left: -8px;
    background: #fce300;
    transform: scale(1.1);
    width: 130% !important;
    height: 130% !important;
    z-index: 1;
  }

  .pie {
    background-color: #f50f64;
    border: 5px solid #f50f64;
  }
  --swiper-theme-color: #fce300;
}

.initialization {
  .real-active-slide {
    .wrapper {
      display: block !important;
    }
    .wrapper.swiper-pagination-bullet-active .filler {
      animation: fill 10s steps(1, end);
      animation-delay: 0.7s;
      -webkit-transform: translate3d (0, 0, 0) 1;
    }

    .wrapper.swiper-pagination-bullet-active .spinner {
      animation: rota 10s linear;
      animation-delay: 0.7s;
      -webkit-transform: translate3d (0, 0, 0);
    }

    .wrapper.swiper-pagination-bullet-active .mask {
      animation: mask 10s steps(1, end);
      animation-delay: 0.7s;
      -webkit-transform: translate3d (0, 0, 0) 1;
    }
  }
}
@media (max-width: 980px) {
  .slider-pagination__title {
    float: none;
    font-size: 24px;
    border-right: none;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .slider-pagination__inner {
    display: block;
  }
}

@media screen and (max-width: 769px) {
}

@media (max-width: 575px) {
  .wrapper {
    &.swiper-pagination-bullet-active {
      background: #fce300 !important;
    }
  }
  .spinner .filler .mask,
  .pie {
    display: inline-block;
  }
}

@media (max-width: 500px) {
  .slider-pagination__title {
    font-size: 16px;
    white-space: nowrap;
    padding: 0;
  }
}
</style>
